<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div class="my-4">
        <h4 class="text-center">Список заявок на вакансии (председатель)</h4>
      </div>

      <div class="my-3">
        <DataTable :value="commissionHeadDecision_form.commissionDecision" showGridlines stripedRows
                   :globalFilterFields="['lastname','firstname']"
                   v-model:filters="filters"
                   :paginator="true"
                   :rows="20"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,20,30,40,50]"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск кандидата"/>
              </span>
            </div>
          </template>
          <template #empty>
            Кандидат не найден
          </template>
          <template #loading>
            Идет поиск кандидатов. Пожалуйста подождите.
          </template>

          <Column header="№" :style="{width: '80px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>

          <Column header="ФИО">
            <template #body="{data}">

              <a @click.prevent="goToUser(data.user)" class="text-decoration-none" href="#">
                {{data.lastname}} {{data.firstname}}
              </a>

            </template>
          </Column>

          <Column header="Контракт" class="text-center" :style="{width: '15%'}">
            <template #body="{data}">
              <div v-if="data.contracts.length">
                <Button label="Контракты" class="p-button-rounded"
                        @click="openAddContractDialog(data.user)"/>
              </div>
              <div v-else>Контрактов нет</div>
            </template>
          </Column>

          <Column header="Дисциплины">
            <template #body="{data}">
              <div v-for="(application, index) in data.applications"
                   :key="index">
                <div class="fw-bold">
                  <a @click.prevent="openDecisionsDialog(application.user_syllabus_id, data.user)"
                     class="text-decoration-none" href="#">
                    {{application.discipline_name}}
                  </a>

                </div>
                <div>{{application.study_level_name}} - {{application.language}}</div>

                <!--                <div v-if="application.commission_head_status == null">-->
                <input class="form-check-input" type="checkbox"
                       :checked="checkedApplications.some(i=>i.user_syllabus_id==application.user_syllabus_id)"
                       @change="changeApplication(application.user_syllabus_id, data.user, application.discipline_name)">
                <!--                </div>-->

                <br>
              </div>
            </template>
          </Column>


          <Column header="Действия" class="text-center" :style="{width: '15%'}">
            <template #body="{data}">
              <Button class="p-button-rounded p-button-success me-1" icon="pi pi-check"
                      @click="openConfirmDialog(data.user)"/>
              <!--              <Button class="p-button-rounded p-button-danger" icon="pi pi-times" @click="openRejectDialog(data.user)"/>-->
            </template>
          </Column>
        </DataTable>


      </div>


    </div>
    <ConfirmDialog></ConfirmDialog>

    <Dialog header="Отказ" v-model:visible="rejectDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}">

      <div class="col-md-12">
        <div class="mt-2">
          <p>Выбранные дисциплины:</p>
          <ul>
            <li v-for="(item, index) in checkedApplications.filter(i=>i.user_id == this.reject_user)"
                :key="index">{{item.discipline_name}}
              <br>
              <textarea class="form-control mb-2" rows="5"
                        @change="changeRejectComment(item.user_syllabus_id, $event)"
                        placeholder="Причина отказа"></textarea>
            </li>

          </ul>
        </div>
      </div>


      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeRejectDialog" class="p-button-text"/>
        <Button label="Отказать" icon="pi pi-check" @click="submitRejectDecision" autofocus/>
      </template>
    </Dialog>

    <Dialog header="Решение по дисциплинам" v-model:visible="confirmDisplay" :breakpoints="{'960px': '75vw'}"
            :style="{width: '50vw'}" :closable="false">

      <div class="col-md-12">
        <div class="mt-4" v-if="decisionDisciplines.filter(i=>i.commission_head_status == 1).length > 0">
          <p>Cписок дисциплин для принятия:</p>
          <ul>

            <li v-for="(item, index) in decisionDisciplines.filter(i=>i.commission_head_status == 1)"
                :key="index">
              {{item.discipline_name}}

            </li>

          </ul>
        </div>
        <div v-else>
          <p>Cписок дисциплин для принятия:</p>
          <ul>
            <li>Дисциплины не выбраны</li>

          </ul>
        </div>

        <div class="mt-4" v-if="decisionDisciplines.filter(i=>i.commission_head_status == null).length > 0">
          <p>Cписок дисциплин для отказа:</p>
          <ul>

            <li v-for="(item, index) in decisionDisciplines.filter(i=>i.commission_head_status == null)"
                :key="index">
              {{item.discipline_name}}
              <textarea class="form-control mb-2" rows="5"
                        @change="changeRejectComment(item.user_syllabus_id, $event)"
                        placeholder="Причина отказа">
              </textarea>
            </li>

          </ul>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeConfirmDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-check" @click="submitDecision" autofocus/>
      </template>
    </Dialog>


    <Dialog header="Решения" v-model:visible="decisionsDisplay" :breakpoints="{'960px': '75vw'}"
            :style="{width: '50vw'}">

      <div class="col-md-12">
        <div class="mt-2">
          <div class="mb-2">
            <i class="pi pi-check" style="font-size: 0.9rem; color: green; font-weight: bold"></i> {{confirmCount}}
            <i class="pi pi-times" style="font-size: 0.9rem; color: red; font-weight: bold"></i> {{rejectCount}}
          </div>
          <ul>
            <li v-for="(item, index) in filteredDecisions"
                :key="index">
              <div v-if="item.commission_status == 1">
                <p>{{convertTimestampToDate(item.created_at)}} - Да</p>
              </div>
              <div v-else>
                <p>{{convertTimestampToDate(item.created_at)}} - Нет</p>
                <p>Причина: {{item.commission_comment}}</p>
              </div>
            </li>

          </ul>
        </div>
      </div>


      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeDecisionsDialog" class="p-button-text"/>
      </template>
    </Dialog>


    <!-- add contract dialog -->
    <Dialog header="Контракты" :visible="displayAddContract" :closable="false" :modal="true"
            :style="{width: '100%', maxWidth: '600px'}">
      <div class="mt-3">
        <h5 class="mb-3">{{contractUserName}}</h5>

        <div v-for="(contract, contractIndex) in contracts" :key="contractIndex" class="border rounded-3 p-2 pb-3 mb-4">
          <div class="row">
            <div class="col-md-3">Кафедра</div>
            <div class="col-md-9 fw-bold">{{contract.department_name}}</div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Выберите тип</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="contract.contract_type">
                <option v-for="(contractType, contractTypeIndex) in contractTypes"
                        :key="contractTypeIndex"
                        :value="contractType?.id">
                  {{ contractType.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Выберите срок</label>
            <div class="col-md-9">
              <select class="form-control form-select" @input="changeContractTerm($event, contract.id)">
                <option v-for="(contractTerm, contractTermIndex) in contractTerms"
                        :key="contractTermIndex"
                        :value="contractTerm.term"
                        :selected="contractTerm.term==contract.contract_term">
                  {{ contractTerm.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="work_start_date" class="col-md-3 col-form-label">
              Дата трудового договора
            </label>
            <div class="col-md-9">
              <input id="work_start_date" type="date" class="form-control" v-model="contract.work_start_date">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="contract_start_date" class="col-md-3 col-form-label">
              С даты
            </label>
            <div class="col-md-9">
              <input id="contract_start_date" type="date" class="form-control" v-model="contract.contract_start_date">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="contract_end_date" class="col-md-3 col-form-label">
              До даты
            </label>
            <div class="col-md-9">
              <input id="contract_end_date" type="date" class="form-control" v-model="contract.contract_end_date">
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-md-3 col-form-label">Должность</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="contract.teaching_position_id">
                <option v-for="(position, positionIndex) in teachingPositions" :key="positionIndex"
                        :value="position.id">
                  {{ position.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="mt-4 text-end">
            <Button label="Сохранить" icon="pi pi-save" @click="addContract(contract.id)" autofocus/>
          </div>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeAddContractDialog" class="p-button-text"/>
      </template>
    </Dialog>
    <!-- end add contract dialog -->


  </div>
</template>

<script>
  import {mapActions, mapState, mapMutations} from 'vuex'
  import {FilterMatchMode, FilterOperator} from 'primevue/api';
  import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
  import {convertDateInputToTimestamp} from "@/utils/helpers/date.helpers"

  export default {
    name: 'CommissionHeadDecision',
    data() {
      return {
        loading: true,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

          'lastname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'firstname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
        checkedApplications: [],
        rejectDisplay: false,
        reject_user: null,

        decisionsDisplay: false,
        decisions_user_syllabus_id: null,
        decisions_user_id: null,

        confirmDisplay: false,
        confirm_user: null,
        decisionDisciplines: [],

        displayAddContract: false,
        contractTypes: [
          {
            id: 1,
            name: 'Штатный'
          },
          {
            id: 2,
            name: 'Совместитель'
          },
          {
            id: 3,
            name: 'Докторантура'
          },
          {
            id: 4,
            name: 'Гпх'
          },
          {
            id: 5,
            name: 'Внутренний совместитель'
          },
        ],
        contractTerms: [
          {
            term: 1,
            name: '1 год'
          },
          {
            term: 2,
            name: '2 года'
          },
          {
            term: 3,
            name: '3 года'
          },
          {
            term: 4,
            name: '1 семестр'
          }
        ],
        contracts: [],
        contractUserName: '',
      }
    },
    computed: {
      ...mapState('commissionHeadDecision', ['commissionHeadDecision_form']),
      filteredDecisions() {
        return this.commissionHeadDecision_form.commissionDecision.find(i => i.user === this.decisions_user_id).applications.find(i => i.user_syllabus_id == this.decisions_user_syllabus_id).decisions
      },
      confirmCount() {
        let decisions = this.commissionHeadDecision_form.commissionDecision.find(i => i.user === this.decisions_user_id).applications.find(i => i.user_syllabus_id == this.decisions_user_syllabus_id).decisions
        return decisions.reduce((sum, i) => (i.commission_status == 1 ? 1 : 0) + sum, 0)
      },
      rejectCount() {
        let decisions = this.commissionHeadDecision_form.commissionDecision.find(i => i.user === this.decisions_user_id).applications.find(i => i.user_syllabus_id == this.decisions_user_syllabus_id).decisions
        return decisions.reduce((sum, i) => (i.commission_status == 0 ? 1 : 0) + sum, 0)
      },
      ...mapState('kkk', ['teachingPositions'])

    },
    methods: {
      ...mapActions('commissionHeadDecision', ['GET_COMMISSION_DECISION', 'PUT_COMMISSION_HEAD_DECISION']),
      ...mapMutations('userinfo', ['SET_USER_ID']),
      ...mapActions('kkk', ['PUT_CONTRACT', 'GET_TEACHING_POSITIONS']),
      convertTimestampToDate,

      openConfirmDialog(user_id) {
        this.confirmDisplay = true
        this.confirm_user = user_id
        console.log(user_id, 'user_id', this.confirm_user, 'this.confirm_user')
        this.decisionDisciplines = this.commissionHeadDecision_form.commissionDecision.find(i => i.user === user_id).applications
        console.log(this.decisionDisciplines, 'decisionDisciplines')
      },
      closeConfirmDialog() {
        this.confirmDisplay = false
      },

      async submitDecision() {
        this.confirmDisplay = false

        let form = this.decisionDisciplines.map(i => ({
          user_syllabus_id: i.user_syllabus_id,
          commission_head_status: i.commission_head_status == 1 ? 1 : 0,
          commission_head_comment: i.commission_head_comment ? i.commission_head_comment : null,

        }))
        console.log(form, 'submit form')

        let postData = await this.PUT_COMMISSION_HEAD_DECISION(form)
        if (postData) {

          await this.GET_COMMISSION_DECISION()
          this.$message({text: 'Заявки приняты'})
          //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
        }
        this.decisionDisciplines = []
        this.confirm_user = null
      },


      openDecisionsDialog(user_syllabus_id, user_id) {
        console.log(user_syllabus_id, 'user_syllabus_id')
        console.log(user_id, 'user_id')
        this.decisionsDisplay = true
        this.decisions_user_syllabus_id = user_syllabus_id
        this.decisions_user_id = user_id
      },
      closeDecisionsDialog() {
        this.decisionsDisplay = false
      },
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      },
      // openRejectDialog(user_id) {
      //   this.rejectDisplay = true
      //   this.reject_user = user_id
      // },
      // closeRejectDialog(user) {
      //   this.rejectDisplay = false
      // },
      // async submitRejectDecision() {
      //   this.rejectDisplay = false
      //   let app = this.checkedApplications.filter(i => i.user_id == this.reject_user)
      //   let form = app.map(i => ({
      //     user_syllabus_id: i.user_syllabus_id,
      //     commission_head_status: 0,
      //     commission_head_comment: i.commission_head_comment,
      //
      //   }))
      //   console.log(form, 'submit reject form')
      //
      //   let postData = await this.PUT_COMMISSION_HEAD_DECISION(form)
      //   if (postData) {
      //
      //     await this.GET_COMMISSION_DECISION()
      //     this.$message({text: 'Заявки на вакансию отклонены'})
      //     //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
      //   }
      //   this.checkedApplications = []
      //   this.reject_user = null
      // },
      // changeRejectComment(user_syllabus_id, e) {
      //   const value = e.target.value
      //
      //   let app = this.checkedApplications.find(i => i.user_syllabus_id == user_syllabus_id)
      //   app.commission_head_comment = value
      //   console.log(app, 'reject comment app')
      //
      // },
      changeRejectComment(user_syllabus_id, e) {
        const value = e.target.value
        console.log(user_syllabus_id, 'changeRejectComment user_syllabus_id')

        this.decisionDisciplines.find(i => i.user_syllabus_id === user_syllabus_id)['commission_head_comment'] = value


        console.log(this.decisionDisciplines, 'comment decisionDisciplines')

      },

      // changeApplication(user_syllabus_id, user, discipline_name) {
      //   console.log(user_syllabus_id, 'user_syllabus_id')
      //   console.log(user, 'user')
      //   console.log(discipline_name, 'discipline_name')
      //   if (this.checkedApplications.find(i => i.user_syllabus_id == user_syllabus_id) && this.checkedApplications.length > 0) {
      //     //let index = this.checkedApplications.indexOf(this.checkedApplications.find(i=>i.user_syllabus_id == user_syllabus_id))
      //     let index = this.checkedApplications.findIndex(i => i.user_syllabus_id == user_syllabus_id)
      //     this.checkedApplications.splice(index, 1);
      //   } else {
      //     this.checkedApplications.push({
      //       user_syllabus_id: user_syllabus_id,
      //       user_id: user,
      //       discipline_name: discipline_name,
      //       commission_head_comment: null
      //     })
      //   }
      //
      //   console.log(this.checkedApplications, 'checkedApplications')
      // },

      changeApplication(user_syllabus_id, user, discipline_name, e) {
        console.log(user_syllabus_id, 'user_syllabus_id')
        console.log(user, 'user')
        console.log(discipline_name, 'discipline_name')

        console.log(this.commissionHeadDecision_form.commissionDecision.find(i => i.user === user).applications.find(i => i.user_syllabus_id === user_syllabus_id)['commission_head_status'], 'SDGSDGSDGSDG')
        if (this.commissionHeadDecision_form.commissionDecision.find(i => i.user === user).applications.find(i => i.user_syllabus_id === user_syllabus_id)['commission_head_status'] == null) {
          this.commissionHeadDecision_form.commissionDecision.find(i => i.user === user).applications.find(i => i.user_syllabus_id === user_syllabus_id)['commission_head_status'] = 1
        } else if (this.commissionHeadDecision_form.commissionDecision.find(i => i.user === user).applications.find(i => i.user_syllabus_id === user_syllabus_id)['commission_head_status'] == 1) {
          this.commissionHeadDecision_form.commissionDecision.find(i => i.user === user).applications.find(i => i.user_syllabus_id === user_syllabus_id)['commission_head_status'] = null
        }
        console.log(this.commissionHeadDecision_form.commissionDecision, 'commissionDecision')
      },
      openAddContractDialog(userId) {
        const user = this.commissionHeadDecision_form.commissionDecision.find(i => i.user === userId)
        if (user) {
          this.contracts = user.contracts.map(i => ({
            ...i,
            work_start_date: convertTimestampToDate(i.work_start_date),
            contract_start_date: convertTimestampToDate(i.contract_start_date),
            contract_end_date: convertTimestampToDate(i.contract_end_date)
          }))

          this.contractUserName = `${user?.lastname} ${user?.firstname}`
          this.displayAddContract = true
        }
      },
      closeAddContractDialog() {
        this.displayAddContract = false
      },
      async addContract(id) {
        const contract = this.contracts.find(i=>i.id==id)

        const form = {
          ...contract,
          work_start_date: convertDateInputToTimestamp(contract.work_start_date),
          contract_start_date: convertDateInputToTimestamp(contract.contract_start_date),
          contract_end_date: convertDateInputToTimestamp(contract.contract_end_date),
        }

        const res = await this.PUT_CONTRACT(form)
        if (res) {
          await this.GET_COMMISSION_DECISION()
          this.$message({title: 'Контракт успешно сохранен'})
        }

        this.closeAddContractDialog()
      },
      changeContractTerm(e, id) {
        const index = this.contracts.findIndex(i=>i.id==id)
        this.contracts[index].contract_term = e.target.value
        this.contracts[index].contract_end_date = this.getContractEndDate(+this.contracts[index].contract_term)
      },
      getContractEndDate(contract_term) {
        if ([1, 2, 3].includes(contract_term)) {
          return `${new Date().getFullYear() + contract_term}-08-31`
        }

        let month = new Date().getMonth()
        let countMonth = month + 6 + 1

        let day = new Date().getDate()
        day = (day < 10 ? '0' : '') + day

        let year = new Date().getFullYear()

        if (countMonth <= 12) {
          month = month + 6
        } else {
          month = countMonth - 12
          year += 1
        }
        month = (month < 10 ? '0' : '') + month
        return `${year}-${month}-${day}`
      },
      // confirm(user) {
      //   this.$confirm.require({
      //     message: 'Вы уверены что хотите принять?',
      //     header: 'Принятие',
      //     icon: 'pi pi-exclamation-triangle',
      //     accept: async () => {
      //       let app = this.checkedApplications.filter(i => i.user_id == user)
      //       let form = app.map(i => ({
      //         user_syllabus_id: i.user_syllabus_id,
      //         commission_head_status: 1,
      //         commission_head_comment: null,
      //
      //       }))
      //       console.log(form, 'submit confirm form')
      //
      //
      //       let putData = await this.PUT_COMMISSION_HEAD_DECISION(form)
      //       if (putData) {
      //
      //         await this.GET_COMMISSION_DECISION()
      //         this.$message({text: 'Заявки на вакансию приняты'})
      //         //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
      //       }
      //       this.checkedApplications = []
      //       this.reject_user = null
      //
      //
      //     },
      //
      //   });
      // },

    },
    async mounted() {
      await this.GET_TEACHING_POSITIONS()

      await this.GET_COMMISSION_DECISION()
      this.loading = false
    },
  }

</script>